import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import GoldtagApp from "./pages/GoldtagApp/GoldtagApp";
import React, { useState, useEffect } from "react";
import GoldtagGateway from "./pages/GoldtagGateway/GoldtagGateway";
import Hakkimizda from "./pages/Hakkimizda/Hakkimizda";
import UcretlerVeLimitler from "./pages/UcretlerVeLimitler/UcretlerVeLimitler";
import SikcaSorulanSorular from "./pages/SikcaSorulanSorular/SikcaSorulanSorular";
import LogoVeKullanim from "./pages/LogoVeKullanim/LogoVeKullanim";
import Contact from "./pages/Contact/Contact";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { regular14, title16, title20 } from "./assets/styles/defaultFonts";
import { title16semibold } from "./assets/styles/GeneralStyles";

import background from "./assets/images/background.jpeg";
import KullaniciSozlesmesi from "./pages/KullaniciSozlesmesi/KullaniciSozlemesi";
import GizlilikPolitikası from "./pages/GizlilikPolitikası/GizlilikPolitikasi";

export default function Router() {
  const navigate = useNavigate();

  //   const [ipAddress, setIPAddress] = useState("");
  //   const myIP = ["95.3.15.194"];
  //   const myIP2 = ["20.127.243.158"];

  //   useEffect(() => {
  //     fetch("https://api.ipify.org?format=json")
  //       .then((response) => response.json())
  //       .then((data) => setIPAddress(data.ip))
  //       .catch((error) => console.log(error));
  //   }, []);

  const routes = useRoutes([
    {
      path: "/",
      element: <GoldtagApp />,
      index: true,
    },
    {
      path: "/GoldtagApp",
      element: <GoldtagApp />,
    },
    {
      path: "/GoldtagGateway",
      element: <GoldtagGateway />,
    },
    {
      path: "/Hakkimizda",
      element: <Hakkimizda />,
    },
    {
      path: "/LogoVeMarkaKullanimKilavuzu",
      element: <LogoVeKullanim />,
    },
    {
      path: "/UcretlerVeLimitler",
      element: <UcretlerVeLimitler />,
    },

    {
      path: "/Iletisim",
      element: <Contact />,
    },

    {
      path: "/SikcaSorulanSorular",
      element: <SikcaSorulanSorular />,
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },

    {
      path: "/404",
      element: <NotAllowed />,
    },
    {
      path: "/KullaniciSozlesmesi",
      element: <KullaniciSozlesmesi />,
    },
    {
      path: "/GizlilikPolitikası",
      element: <GizlilikPolitikası />,
    },
  ]);
  function NotAllowed() {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "100px",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography sx={title20}>Aradığınız sayfaya ulaşılamıyor..</Typography>
        <Typography sx={title20}>Hata Kodu : 404</Typography>

        <Button
          sx={{
            marginTop: "52px",
            width: "200px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#D6B87C",
            borderRadius: "12px",
          }}
          onClick={() => navigate("/GoldtagApp")}
        >
          <Typography sx={[title16semibold, { color: "white" }]}>
            Anasayfaya Dön.
          </Typography>
        </Button>
      </Box>
    );
  }

  return routes;
}
