import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  bigtitle,
  frame34,
  imageStyle,
  imgS,
  leftFrame,
  marginTop48,
  secIm,
  secureContainer,
  subText,
  subTtle,
  title,
} from "./GoldtagSecurityCSS";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { Minilogo, KuveytTurk1 } from "../../../assets/svgs/GoldtagAppSvgs";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import KT from "../../../assets/svgs/Minilogos/KT";

function GoldtagSecurity(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const boxRef = React.useRef(null);
  const boxRef2 = React.useRef(null);

  useGSAP(() => {
    const box = boxRef.current;
    const box2 = boxRef2.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: box,
        start: "top bottom",
        toggleActions: "play none none none",
      },
    });

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: box2,
        start: "top bottom",
        toggleActions: "play none none none",
      },
    });

    tl.from(box, {
      opacity: 0,
      x: -200,
      duration: 1,
      ease: "sine",
      delay: 0.5,
    });

    tl2.from(box2, {
      opacity: 0,
      x: 200,
      duration: 1,
      ease: "sine",
      delay: 0.5,
    });

    return () => {
      tl.kill();
      tl2.kill();
    };
  }, []);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, []);

  return (
    <Box sx={secureContainer}>
      {isMobile ? (
        <Box ref={boxRef2} style={{ height: "400px" }}>
          <img
            src="https://goldtagimages.s3.eu-north-1.amazonaws.com/app-mockup.webp"
            alt="Goldtag Win"
            style={imgS}
            width="373"
            height="523"
          />
        </Box>
      ) : null}
      <Box sx={leftFrame} ref={boxRef}>
        <Box>
          <Minilogo />
        </Box>
        <Typography sx={title}>Paran Goldtag ile Güvende!</Typography>
        <Typography sx={subText}>
          Goldtag sayesinde kullanıcılar, altın fiyatlarını takip edebilir,
          birikimlerini mobil cihazları üzerinden yönetebilir ve herhangi bir
          yerden altın alım-satım işlemleri yapabilirler. Goldtag kullanıcıları
          birikimlerini daha güvenli ve kolay bir şekilde yönetmelerini sağlar.
        </Typography>
        <Box sx={frame34}>
          <Typography
            sx={[
              subText,
              {
                fontWeight: "bold",
              },
            ]}
          >
            Goldtag platformu üzerinden satılan altın, gümüş ve platin emtiaları{" "}
            <KT /> bankası kasalarında saklanmaktadır.
          </Typography>
        </Box>
        <Box sx={marginTop48}>
          <Box sx={{ marginRight: "70px" }}>
            <Typography sx={bigtitle}>₺1 Milyar +</Typography>
            <Typography sx={subTtle}>Kıymetli Maden Satışı</Typography>
          </Box>
          <Box>
            <Typography sx={bigtitle}>450.000+</Typography>
            <Typography sx={subTtle}>Farklı Müşteri</Typography>
          </Box>
        </Box>
      </Box>
      {!isMobile ? (
        <Box ref={boxRef2}>
          <img
            src="https://goldtagimages.s3.eu-north-1.amazonaws.com/app-mockup.webp"
            alt="Goldtag Win"
            style={secIm}
            width="650"
            height="900"
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default GoldtagSecurity;
