import React, { Suspense } from "react";

import Marquee from "react-simple-marquee";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { lazy } from "react";
import { maqrCon, marqmob } from "./MarqueCSS";

const margn2 = {
  marginRight: "2rem",
};

const LazyKuveytTurk = lazy(() =>
  import("../../assets/svgs/Marquee/KuveytTurk")
);
const LazyMigros = lazy(() => import("../../assets/svgs/Marquee/Migros"));
const LazyPapara = lazy(() => import("../../assets/svgs/Marquee/Papara"));
const LazyPaycell = lazy(() => import("../../assets/svgs/Marquee/Paycell"));
const LazyCollendi = lazy(() => import("../../assets/svgs/Marquee/Collendi"));
const LazyRePie = lazy(() => import("../../assets/svgs/Marquee/RePie"));
const LazyMoneyPay = lazy(() => import("../../assets/svgs/Marquee/MoneyPay"));
const LazyTp = lazy(() => import("../../assets/svgs/Marquee/Tp"));
const LazyAhl = lazy(() => import("../../assets/svgs/Marquee/Ahl"));
const LazyAhlPay = lazy(() => import("../../assets/svgs/Marquee/AhlPay"));
const LazyCollendiMenkul = lazy(() =>
  import("../../assets/svgs/Marquee/CollendiMenkul")
);
const LazyFinberg = lazy(() => import("../../assets/svgs/Marquee/Finberg"));

function Marque(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {!isMobile ? (
        <Marquee speed={0.6} loop={0} pauseOnHover={true} style={maqrCon}>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyMoneyPay />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyMigros />
            </Suspense>
          </div>

          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <div>
                <LazyPapara />
              </div>
            </Suspense>
          </div>

          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyKuveytTurk />
            </Suspense>
          </div>

          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyPaycell />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyCollendi />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyCollendiMenkul />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <div>
                <LazyRePie />
              </div>
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyTp />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyAhl />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyAhlPay />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyFinberg />
            </Suspense>
          </div>
        </Marquee>
      ) : (
        <Marquee
          speed={0.6}
          loop={0}
          gradient={false}
          pauseOnHover={true}
          style={marqmob}
        >
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyMoneyPay />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyMigros />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyKuveytTurk />
            </Suspense>
          </div>

          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <div>
                <LazyPapara />
              </div>
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyPaycell />
            </Suspense>
          </div>

          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyCollendi />
            </Suspense>
          </div>

          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyCollendiMenkul />
            </Suspense>
          </div>

          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyTp />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyAhl />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyAhlPay />
            </Suspense>
          </div>
          <div style={margn2}>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyFinberg />
            </Suspense>
          </div>
        </Marquee>
      )}
    </>
  );
}

export default Marque;
