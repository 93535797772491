import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import { regular14, title16, title20 } from "../../assets/styles/defaultFonts";
import { LocationIcon, Phone } from "../../assets/svgs/Contact";
import { Mail } from "../../assets/svgs/FooterSvgs";
import { frame213, frame432, mapContainer } from "./IletisimCSS";
import { mobtit, title56 } from "../LogoVeKullanim/LogoCSS";
import { mobcontainer } from "../UcretlerLimitler/UcretlerVeLimitlerCSS";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

function Iletisim(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useGSAP(() => {
    gsap.from(ref.current, {
      opacity: 0,
      x: -200,
      duration: 0.6,
      ease: "circ.inOut",
    });

    gsap.from(ref1.current, {
      opacity: 0,
      x: -300,
      duration: 1,
      ease: "power3.in",
    });

    gsap.from(ref2.current, {
      opacity: 0,
      x: 300,
      duration: 1,
      ease: "power3.in",
    });

    gsap.from(ref3.current, {
      opacity: 0,
      x: -300,
      duration: 1,
      ease: "power3.in",
    });
  });

  return (
    <>
      {isMobile ? (
        <Box style={mobcontainer}>
          <Typography sx={mobtit}>Bize Ulaşın</Typography>

          <Box sx={frame213}>
            <Box
              sx={{
                width: "343px",
                alignSelf: "center",
              }}
            >
              <Box sx={frame432}>
                <Box sx={{ width: "30px" }}>
                  <LocationIcon />
                </Box>
                <Box>
                  <Typography sx={title20}>Ofis Adresi</Typography>
                  <Typography sx={[regular14, { width: "200px" }]}>
                    Mustafa Kemal Mah. 2156 Sk. No: 14b Çankaya / Ankara
                    (PTT'nin yanı )
                  </Typography>
                </Box>
              </Box>

              <Box sx={frame432}>
                <Box sx={{ width: "30px" }}>
                  <Phone />
                </Box>

                <Box>
                  <Typography sx={title16}>Telefon</Typography>
                  <Typography sx={regular14}>0 (850) 307 2490</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", gap: "1rem", marginBottom: "40px" }}>
                <Box sx={{ width: "30px" }}>
                  <Mail />
                </Box>

                <Box>
                  <Typography sx={title16}>E-Posta</Typography>
                  <Typography sx={regular14}>info@goldtag.org</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                alignSelf: "center",
                marginBottom: "72px",
                borderRadius: "36px",
                marginTop: "32px",
                width: "343px",
                overflow: "hidden",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  "https://maps.app.goo.gl/aAjGsTZwrRKSviKT6",
                  "_blank"
                )
              }
            >
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/MapImg.webp          "
                }
                alt="Goldtag Win"
                width="343"
                height="200"
                style={{ borderRadius: "36px" }}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Typography sx={[title56, { marginBottom: "72px" }]} ref={ref}>
            Bize Ulaşın
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "7.5rem",
              justifyContent: "center",
              paddingLeft: "6rem",
              alignSelf: "center",
            }}
          >
            <Box
              sx={{
                width: "466px",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  marginBottom: "40px",
                  alignSelf: "center",
                }}
                ref={ref1}
              >
                <LocationIcon />
                <Box style={{ paddingBottom: "1rem" }}>
                  <Typography sx={[title16, { marginBottom: "1rem" }]}>
                    Ofis Adresi
                  </Typography>
                  <Typography sx={regular14}>
                    Mustafa Kemal Mah. 2156 Sk. No: 14b Çankaya / Ankara
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", gap: "1rem", marginBottom: "40px" }}
                ref={ref2}
              >
                <Phone />
                <Box style={{ paddingBottom: "1rem" }}>
                  <Typography sx={[title16, { marginBottom: "1rem" }]}>
                    Telefon
                  </Typography>
                  <Typography sx={regular14}>0 (850) 307 2490</Typography>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", gap: "1rem", marginBottom: "40px" }}
                ref={ref3}
              >
                <Mail />
                <Box>
                  <Typography sx={[title16, { marginBottom: "1rem" }]}>
                    E-Posta
                  </Typography>
                  <Typography sx={regular14}>info@goldtag.org</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={mapContainer}
            onClick={() =>
              window.open("https://maps.app.goo.gl/aAjGsTZwrRKSviKT6", "_blank")
            }
          >
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/MapImg.webp          "
              }
              alt="Goldtag Win"
              width="640"
              height="440"
              style={{ borderRadius: "36px" }}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default Iletisim;
