import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";

import {
  cntrcntr,
  frm,
  infos,
  subInfo,
  subtext,
  whycontainer,
  whytitle,
} from "./WhyGatewayCSS";
import { title } from "../../goldtagAppComponents/GoldtagFeatures/GoldtagFeaturesCSS";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

function WhyGateway(props) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const boxRef = useRef(null);
  const boxRef2 = useRef(null);

  useGSAP(() => {
    const box = boxRef.current;
    const box2 = boxRef2.current;

    // GSAP animasyonu oluşturma
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: box,
        start: "top bottom", // Scrollun nerede başlayacağı
        toggleActions: "play none none none", // Animasyonun nasıl oynatılacağı
        // markers: true, // Geliştirme amaçlı işaretçilerin gösterilip gösterilmeyeceği
      },
    });

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: box2,
        start: "top bottom", // Scrollun nerede başlayacağı
        toggleActions: "play none none none", // Animasyonun nasıl oynatılacağı
        // markers: true, // Geliştirme amaçlı işaretçilerin gösterilip gösterilmeyeceği
      },
    });

    tl.from(box, {
      opacity: 0,
      x: -200,
      delay: 0.6,
      duration: 1.2,

      ease: "sine",
      delay: 1,
    });

    tl2.from(box2, {
      opacity: 0,
      x: 200,
      duration: 1.2,
      ease: "sine",
      delay: 0.6,
    });

    // Component kaldırıldığında scrollTrigger'ı temizle
    return () => {
      tl.kill();
      tl2.kill();
    };
  }, []);

  return (
    <>
      {/* ---------------Lorem ipsum----------------- */}
      <Box sx={whycontainer}>
        <Box ref={boxRef}>
          {isMobile ? (
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/MockupWrap.webp"
              }
              alt="Goldtag Win"
              width="343"
              height="295"
            />
          ) : (
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/MockupWrap.webp"
              }
              alt="Goldtag Win"
              width="570"
              height="490"
            />
          )}
        </Box>
        <Box ref={boxRef2}>
          <Typography sx={whytitle}>Neden Goldtag Gateway?</Typography>
          <Typography sx={subtext}>
            Goldtag Gateway ile kıymetli maden ve tarım emtia gibi varlıkların
            API’lerine erişebilirsiniz. 21 Günde entegrasyon sağlayabilir, 7/24
            uygun fiyatlarla kullanıcılarınız emtia hizmetleri
            sağlayabilirsiniz. Sorunlarınızı hızlıca çözebileceğiniz destek
            ekibimiz ile her zaman iletişime geçebilirsiniz.
          </Typography>
          <Box sx={frm}>
            <Box sx={cntrcntr}>
              <Typography sx={infos}>₺1 Milyar +</Typography>
              <Typography sx={subInfo}>İşlem Hacmi</Typography>
            </Box>
            <Box sx={cntrcntr}>
              <Typography sx={infos}>450.000+</Typography>
              <Typography sx={subInfo}>Farklı Müşteri</Typography>
            </Box>
            <Box sx={cntrcntr}>
              <Typography sx={infos}>81</Typography>
              <Typography sx={subInfo}>İlden Müşteri</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default WhyGateway;
